import { Collection } from '../../types/Collection';
import React, { useState } from 'react';
import { Typography } from 'antd';
import { chunk } from 'lodash';
import CollectionCard from './CollectionCard';
import Section from '../Section';
import BasePagination from '../pagination/BasePagination';

const PAGE_SIZE = 24;

const CollectionParent = ({ collections }: { collections?: Collection[] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const collectionsByPage = chunk(collections, PAGE_SIZE)[currentPage - 1];
  const total = collections?.length || 0;
  return (
    <Section>
      <div className="grid gap-3 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 w-full">
        {collectionsByPage?.map((collection) => (
          <CollectionCard collection={collection} />
        ))}
      </div>
      <BasePagination
        current={currentPage}
        total={total}
        pageSize={PAGE_SIZE}
        onChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </Section>
  );
};

export default CollectionParent;
