import { Link } from 'gatsby';
import { Collection } from '../../types/Collection';
import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const CollectionCard = ({ collection }: { collection: Collection }) => {
  const imgUrl = collection.metadata?.['img-url'];
  return (
    <Link
      to={`/collections/${collection.handle}`}
      className="border-light-divider rounded-[6px] border shadow-small overflow-hidden cursor-pointer transition-all hover:shadow-hover hover:border-primary-6"
    >
      <div
        style={{ backgroundImage: `url(${imgUrl})` }}
        className="pt-[75%] bg-cover bg-center border-b border-light-divider"
      />
      <div className="w-full p-4 text-ellipsis overflow-hidden whitespace-nowrap bg-white">
        <Text>{collection.title}</Text>
      </div>
    </Link>
  );
};

export default CollectionCard;
