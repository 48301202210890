import MainLayout from "../../components/layout/MainLayout"
import React from "react"
import { Router } from "@reach/router"
import CollectionPage from "../../components/collection/CollectionPage"

const CollectionList = () => {
  return (
    <MainLayout>
      <Router basepath="/collections">
        <CollectionPage path="/:handle" />
      </Router>
    </MainLayout>
  )
}


export default CollectionList
