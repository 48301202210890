import React from 'react';
import { useCollection } from '../../hooks/use-collection';
import CollectionDetail from './CollectionDetail';
import Breadcrumb from './CollectionBreadcrumb';
import CollectionParent from './CollectionParent';
import SearchEngineOptimization from '../utility/seo';

const CollectionPage = (props) => {
  const { collections, hierarchyCollections } = useCollection();
  const { handle } = props;
  const selectedCollection = collections?.find((c) => c.handle == handle);
  const children = hierarchyCollections?.find(
    (c) => c.handle == handle
  )?.children;
  return (
    <>
      <SearchEngineOptimization
        title={selectedCollection?.title || 'ประเภทสินค้า'}
      />
      <Breadcrumb collection={selectedCollection} />
      {selectedCollection?.metadata?.children ? (
        <CollectionParent collections={children} />
      ) : (
        <CollectionDetail handle={handle} />
      )}
    </>
  );
};

export default CollectionPage;
